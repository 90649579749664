import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ProductService } from 'src/app/core/services/product.service';
import { SharedService } from 'src/app/core/services/shared.service';
import { UploadGalleryComponent } from 'src/app/shared/ui/upload-gallery/upload-gallery.component';

@Component({
  selector: 'app-sub-category-modal',
  templateUrl: './sub-category-modal.component.html',
  styleUrls: ['./sub-category-modal.component.scss']
})
export class SubCategoryModalComponent implements OnInit {
  subCatForm: FormGroup;
  isSuggestSubCat = false;
  isSubCatSubmitted = false;
  isSubmittingSubCat = false;
  checkInterval: any;
  isUrlSubCat = false;
  @Input() catId: any
  @Input() subCategory: any
  constructor(private fb: FormBuilder, private api: ProductService,
    private toastr: ToastrService, private sharedService: SharedService,
    public activeModal: NgbActiveModal, private modalService: NgbModal) { }
  ngOnInit(): void {
    this.resetSubCatForm(this.subCategory ? this.subCategory : '')
  }
  resetSubCatForm(data?) {
    this.subCatForm = this.fb.group({
      id: [data ? data.id : null],
      category: [this.catId ? this.catId : ''],
      name: [data ? data.name : null, [Validators.required]],
      entry_type: [data ? data.entry_type : '', [Validators.required]],
      url_param: [data ? data.url_param : null, [Validators.required]],

      internal_code: [data ? data.internal_code : null, [Validators.required]],
      price: [data ? data.price : null, [Validators.required]],

      as_per_embassy: [
        data ? (data.as_per_embassy ? data.as_per_embassy : false) : false,
      ],
      duration_value: [data ? data.duration_value : null],
      duration_unit: [data ? data.duration_unit : "Day"],

      validity_as_per_embassy: [
        data
          ? data.validity_as_per_embassy
            ? data.validity_as_per_embassy
            : false
          : false,
      ],
      validity_value: [data ? data.validity_value : null],
      validity_unit: [data ? data.validity_unit : "Day"],
      icon: [data ? data.icon : '', [Validators.required],],
      processing_time_min: [
        data ? data.processing_time_min : null,
        [Validators.required],
      ],
      processing_time_max: [
        data ? data.processing_time_max : null,
        [Validators.required],
      ],
      processing_time_unit: [data ? data.processing_time_unit : "Hour"],
    });
  }

  closeModal() {
    this.activeModal.close();
  }

  generateUrlParam(isCat, text) {
    if (!isCat && this.isSuggestSubCat) this.subCatForm.get('url_param').setValue(text.toLowerCase().replace(/[^\w\s]/gi, '').split(' ').join('-'));
  }
  onSubCatSubmit(form) {
    if (form.valid) {
      this.isSubmittingSubCat = true;
      if (this.subCategory) {
        this.api
          .editSubcategories(this.subCategory.id, form.value)
          .subscribe(
            (result: any) => {
              // this.showSubCatForm = false;
              this.isSubmittingSubCat = false;
              // this.showSubcategories(this.selectedCatId);
              // this.resetSubCatForm();
              this.toastr.success("Sub-Category updated Successfully!");
              this.activeModal.close(true)
            },
            (error) => {
              this.isSubmittingSubCat = false;
            }
          );
      } else {
        this.api.setSubcategories(form.value).subscribe(
          (result: any) => {
            //this.subCatList.push(result);
            //this.showSubCatForm = false;
            //this.resetSubCatForm();
            this.isSubmittingSubCat = false;
            this.toastr.success("Sub-Category Added Successfully!");
            this.activeModal.close(true)
          },
          (error) => {
            this.isSubmittingSubCat = false;
          }
        );
      }
    } else {
      this.isSubCatSubmitted = true;
      this.toastr.warning("Fill all the fields to continue");
    }
  }

  changeRequiredDuration(value, isDuration) {
    if (isDuration) {
      if (value) {
        this.subCatForm
          .get("duration_value")
          .setValidators(Validators.required);
        this.subCatForm.get("duration_unit").setValidators(Validators.required);
      } else {
        this.subCatForm.get("duration_value").clearValidators();
        this.subCatForm.get("duration_unit").clearValidators();
      }
    } else {
      if (value) {
        this.subCatForm
          .get("validity_value")
          .setValidators(Validators.required);
        this.subCatForm.get("validity_unit").setValidators(Validators.required);
      } else {
        this.subCatForm.get("validity_value").clearValidators();
        this.subCatForm.get("validity_unit").clearValidators();
      }
    }
  }

  checkURLParam(url, isCat) {
    let data: any;
    clearTimeout(this.checkInterval);

    this.isUrlSubCat = true;
    data = {
      product_url_param: url,
      category_id: this.catId,
    };
    if (this.subCategory) data.id = this.subCategory.id;


    this.checkInterval = setTimeout(() => {
      this.sharedService.checkUrlParam(data).subscribe((result) => {
        // this.isDupUrl = result.is_exists;
        // if (!this.isDupUrl) {
        //   if (isCat) this.isUrlCat = false;
        //   else this.isUrlSubCat = false;
        // }
        // if (this.isDupUrl) this.toastr.warning("URL Param must be unique");
        if (result.is_exists) {
          this.isUrlSubCat = true;
          this.toastr.warning("URL Param must be unique")
        } else {
          this.isUrlSubCat = false;
        };
      });
    }, 1000);
  }

  openUploadModal(field) {
    const modalRef = this.modalService.open(UploadGalleryComponent,
      {
        size: 'xl',
        scrollable: true,
        windowClass: 'modal-big-lg',
        keyboard: false,
        backdrop: 'static'
      });
    modalRef.componentInstance.uploadDetails = {};
    modalRef.result.then((result) => {
      if (result.status) {
        this.subCatForm.get(field).setValue(result.image);
      }
    });
  }

}
