<div class="row mb-md-2">
  <div class="col-md-3">
    <div id="tickets-table_filter" class="dataTables_filter">
      <div class="d-flex">
        <button type="button" class="btn btn-success me-2" (click)="backToCategory()">
          Back
        </button>
        <input class="form-control" type="text" placeholder="Search subcategory" (keyup)="searchSubCat(searchTerm)"
          [(ngModel)]="searchTerm" />
        <div class="d-flex" autocomplete="off" (click)="searchTerm = ''; searchSubCat(searchTerm)">
          <a href="javascript:void(0)" class="my-auto mx-2">
            <i class="fa fa-times text-danger"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-9 text-end">
    <!-- <button
        type="button"
        class="btn btn-primary me-2"
        >
        Change Position
      </button> -->
    <button type="button" class="btn btn-success" (click)="openAddorEditModal()">
      <i class="mdi mdi-plus me-1"></i> Add Subcategory
    </button>
  </div>
</div>
<div class="table-responsive">
  <table id="basic-datatable" class="table table-centered datatables dt-responsive nowrap table-card-list">
    <thead>
      <!-- <div class="col-md-12 mb-2" >
            <div class="border shadow text-center py-2 cpr"> No Sub-Categories<br>
            </div>
        </div> -->
      <div class="col-md-12 mb-2" *ngIf="isGettingSubCat">
        <div class="border shadow text-center py-2 cpr">
          <i class="fa fa-2x fa-spin fa-spinner fa-pulse"></i>
        </div>
      </div>
      <tr>
        <th>Subcategories</th>
        <th class="text-center">Active</th>
        <th class="text-center">Courier</th>
        <th class="text-center">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="subCategriesUnderCountry.length == 0">
        <td class="w-100 text-center">No Sub-Categories</td>
      </tr>
      <!-- *ngFor="let category of subCategriesUnderCountry; let i = index" -->
      <tr *ngFor="let category of subCategriesUnderCountry; let i = index">
        <td>
          {{ category?.name }}
        </td>
        <td>
          <div class="d-flex">
            <div class="form-check form-switch form-switch-lg m-auto" dir="ltr">
              <input type="checkbox" class="form-check-input" id="hotel{{ i }}"
                [attr.checked]="category?.display_status ? true : null" [disabled]="
                      !checkPermission(
                        'product_management_country_active'
                      )
                    " (change)="changeStatus($event, category)" />
            </div>
          </div>
        </td>
        <td>
          <div class="d-flex">
            <div class="form-check form-switch form-switch-lg m-auto" dir="ltr">
              <input type="checkbox" class="form-check-input" id="hotel{{ i }}"
                [attr.checked]="category?.courier_add ? true : null" (change)="changeCourierStatus($event, category)" />
            </div>
          </div>
        </td>
        <td class="text-center" (click)="openAddorEditModal(category)">
          <a class="cpr"><i class="fas fa-edit text-info"></i></a>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<!--SubCategory form-->