import { Component, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { V3ServicesService } from 'src/app/core/services/v3-services.service';

@Component({
  selector: 'app-product-info',
  templateUrl: './product-info.component.html',
  styleUrls: ['./product-info.component.scss']
})
export class ProductInfoComponent {
  @Input() country;
  @Input() page;
  @Input() product;

  infoForm: FormGroup;
  hasChanges = false;
  isSubmitted = false;

  isUpdating = false;
  deletedInfo = [];

  constructor(private fb: FormBuilder,
    private toastr: ToastrService,
    private api: V3ServicesService) { }

  ngOnInit(): void {
    this.resetForm();
    this.getProductInfo();
  }

  resetForm() {
    this.infoForm = this.fb.group({
      info: this.fb.array([])
    });
  }

  getProductInfo() {
    this.api.getProductInfo(this.product).subscribe(
      (result: any) => {
        this.setData(result.info);
      })
  }

  setData(result) {
    this.infoForm = this.fb.group({
      info: new FormArray([]),
    });
    result.forEach(ao => {
      (this.infoForm.get('info') as FormArray).push(
        this.fb.group({
          id: [ao.id],
          text: [ao.text, [Validators.required]],
          is_enabled: [ao.is_enabled],
          is_updated: [false],
          is_new: [false],
        })
      );
    });
  }

  info(): FormArray {
    return this.infoForm.get("info") as FormArray
  }

  newinfo(): FormGroup {
    return this.fb.group({
      text: ['', [Validators.required]],
      is_enabled: [false],
      is_updated: [false],
      is_new: [true],
    })
  }

  addinfo() {
    this.info().push(this.newinfo());
  }

  removeinfo(i: number) {
    this.isSubmitted = false;
    this.infoForm.value.info[i].is_deleted = true;
    if (this.infoForm.value.info[i].hasOwnProperty("id")) this.deletedInfo.push(this.infoForm.value.info[i]);
    this.info().removeAt(i);
  }

  dataChanged(i) {
    let ae = this.infoForm.value.info[i];
    if (ae.is_new) {
      delete ae.is_updated;
    } else {
      ae.is_updated = true;
      delete ae.is_new;
    }

  }

  onSubmit(form) {

    if (form.valid) {
      this.isUpdating = true;
      this.deletedInfo.forEach(ae => {
        delete ae.is_updated;
        delete ae.is_new;
        form.value.info.push(ae);
      })

      this.api.setProductInfo({
        product_id: this.product,
        info: form.value.info
      }).subscribe(
        () => {
          this.toastr.success("Content Updated Successfully!");
          this.getProductInfo();
          this.hasChanges = false;
          this.isUpdating = false;
        },
        () => {
          this.isUpdating = false;
        })
    }
  }
}
