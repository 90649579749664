import { Component } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { ProductService } from "src/app/core/services/product.service";
import { ProductV2Service } from "src/app/core/services/product-v2.service";
import { SharedService } from "src/app/core/services/shared.service";
import { courierForm } from "src/app/core/models/add-on.model";
import { UploadGalleryComponent } from 'src/app/shared/ui/upload-gallery/upload-gallery.component';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";


@Component({
  selector: 'app-addon-courier',
  templateUrl: './addon-courier.component.html',
  styleUrls: ['./addon-courier.component.scss']
})
export class AddonCourierComponent {
  constructor(
    private sharedService: SharedService,
    private api: ProductService,
    private apiv2: ProductV2Service,
    private fb: FormBuilder,
    private tosatr: ToastrService,
    private modalService: NgbModal
  ) { }

  countryList = this.sharedService.addedCountryList;
  country = this.sharedService.selectedCountryId;
  sources = [];
  courierData: any;
  courierForm: FormGroup;

  ngOnInit(): void {
    if (this.countryList.length == 0) this.getCountriesList();
    if (this.country) this.getCourier();
    this.getSources();
    this.initForm();
  }

  getCountriesList() {
    this.api.getCountryDetails().subscribe((response: any) => {
      this.countryList = response;
      this.sharedService.addedCountryList = this.countryList;
    });
  }

  selectCountry(country) {
    this.country = country;
    this.sharedService.selectedCmsCountryId = country;
    this.sharedService.selectedCmsCategoryId = null;
    this.sharedService.cmsCategoryList = [];

    this.sharedService.selectedCmsProductId = null;
    this.sharedService.cmsProductList = [];

    this.getCourier();
  }

  initForm(data?: any) {
    this.courierForm = this.fb.nonNullable.group<courierForm>({
      heading: new FormControl(data?.heading ?? null, Validators.required),
      additional_price: new FormControl(data?.additional_price ?? null, Validators.required),
      applicable_for: new FormControl(data?.applicable_for ?? null, Validators.required),
      price: new FormControl(data?.price ?? null, Validators.required),
      icon: new FormControl(data?.icon ?? null, Validators.required),
      benefits: this.fb.array(
        (data?.benefits ?? []).map((benefit: string) =>
          new FormControl(benefit, Validators.required)
        ),
        Validators.required // This ensures the FormArray itself is required
      ),
      sources: new FormControl(data?.sources ?? null, Validators.required),
    });
  }

  get benefits(): FormArray {
    return this.courierForm.get('benefits') as FormArray;
  }

  addBenefit() {
    this.benefits.push(new FormControl('', Validators.required));
  }

  removeBenefit(index: number) {
    this.benefits.removeAt(index);
  }

  getSources() {
    this.apiv2.getWhitelabelSources().subscribe((response: any) => {
      this.sources = response;
    });
  }

  getCourier() {
    this.apiv2.getCourierDetails(this.country).subscribe((response: any) => {
      this.courierData = response[0] || null;
      this.initForm(this.courierData);
    });
  }

  submitCourier(form: FormGroup) {
    if (!form.valid) {
      this.markFormGroupTouched(form);
      this.tosatr.warning('Fill All Fields!');
      return;
    }

    const isUpdate = !!this.courierData?.id;
    const apiCall = isUpdate
      ? this.apiv2.updateCourierDetails(this.courierData.id, { ...form.value, country: this.country })
      : this.apiv2.setCourierDetails({ ...form.value, country: this.country });

    apiCall.subscribe((response) => {
      const successMessage = isUpdate
        ? 'Courier Details Updated Successfully!'
        : 'Courier Details Added Successfully!';
      this.tosatr.success(successMessage);
      this.courierData = response;
      this.initForm(this.courierData);
    });
  }


  private markFormGroupTouched(formGroup: FormGroup | FormArray) {
    Object.keys(formGroup.controls).forEach((key) => {
      const control = formGroup.get(key);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup || control instanceof FormArray) {
        this.markFormGroupTouched(control); // Recursively mark child controls
      }
    });
  }

  openUploadModal(field) {
    const modalRef = this.modalService.open(UploadGalleryComponent,
      {
        size: 'xl',
        scrollable: true,
        windowClass: 'modal-big-lg',
        keyboard: false,
        backdrop: 'static'
      });
    modalRef.componentInstance.uploadDetails = {};
    modalRef.result.then((result) => {
      if (result.status) {
        this.courierForm.get(field).setValue(result.image);
      }
    });
  }

}
