import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProductService } from 'src/app/core/services/product.service';
import { environment } from 'src/environments/environment';
import { SharedService } from 'src/app/core/services/shared.service';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-country-details',
  templateUrl: './country-details.component.html',
  styleUrls: ['./country-details.component.scss']
})

export class CountryDetailsComponent implements OnInit {

  @Input() countriesList;
  @Input() fromAdd;
  @Input() countryData;
  countryForm: FormGroup;
  selectedCountry = null;
  flagSrc = environment.countryFlagSrc;
  addedCountryId: number;
  isCountryDetails:boolean;
  countryDetailsId:number;
  isSubmitted = false;
  isDupUrl = false;
  isEdit = false;
  @Output("showNextStep") showNextStep: EventEmitter<any> = new EventEmitter();
  checkInterval: any;
  countryId = null;
  categories = [
    {id:1, name: 'Stamping'}, 
    {id:2, name:'E Visa'}, 
    {id:3, name:'Visa on Arrival'}, 
    {id:4, name:'Visa Not Required'},
    {id:5, name:'Schengen Visa'}
  ]
  selectedCategories = [];
  @Output("closeModal") closeModal: EventEmitter<any> = new EventEmitter();

  constructor(private fb: FormBuilder, private api: ProductService,
    private sharedService: SharedService, private toastr: ToastrService, public activeModal: NgbActiveModal) {
    this.countryForm = this.fb.group({
      'name': ['', [Validators.required]],
      'code': ['', [Validators.required]],
      'phone_code': ['', [Validators.required]],
      'visa_type': [null, [Validators.required]],
      'url_param': [null, [Validators.required]],
      'flag_url': [''],
      'search_key_words': ['']
    })
  }

  ngOnInit(): void {
    if(!this.fromAdd){
      // this.getCountryById(this.countryData.id);
      this.countryForm = this.fb.group({
        'name': [this.countryData.name, [Validators.required]],
        'code': [this.countryData.code, [Validators.required]],
        'phone_code': [this.countryData.phone_code, [Validators.required]],
        'visa_type': [this.countryData.visa_type, [Validators.required]],
        'url_param': [this.countryData.url_param, [Validators.required]],
        'flag_url': [this.flagSrc + '/' + this.countryData.code.toLowerCase() + '.webp'],
        'search_key_words': [this.countryData.search_key_words]
      })
      this.selectedCategories = this.countryData.visa_type ? this.countryData.visa_type.split(',') : '';
    }
  }

  selectCountry() {
    let country = this.countriesList.filter(ae => ae.code == this.selectedCountry)[0];
    this.api.getCountryAllData(country.id).subscribe(
      (result: any) => {
        this.sharedService.countryAllData = result;
        this.isCountryDetails = result.is_country_details;
        if(this.isCountryDetails){
          this.isEdit = true;
          this.countryId = result.country_details.id; 
          this.countryDetailsId = result.country_details.id;
          this.sharedService.selectedCountryId = this.countryDetailsId;
          this.countryForm = this.fb.group({
            'name': [result.country_details.name, [Validators.required]],
            'code': [result.country_details.code, [Validators.required]],
            'phone_code': [result.country_details.phone_code, [Validators.required]],
            'visa_type': [result.country_details.visa_type, [Validators.required]],
            'url_param': [result.country_details.name.replace(/[^\w\s]/gi, '').replace(/\s+/g, '-').toLowerCase(), [Validators.required]],
            'flag_url': [this.flagSrc + '/' + result.country_details.code.toLowerCase() + '.png'],
            'search_key_words': [result.country_details.search_key_words]
          })
        } else {
          this.countryForm = this.fb.group({
            'name': [country.name, [Validators.required]],
            'code': [country.code, [Validators.required]],
            'phone_code': [country.phone_code, [Validators.required]],
            'visa_type': [country.visa_type, [Validators.required]],
            'url_param': [country.name.replace(/[^\w\s]/gi, '').replace(/\s+/g, '-').toLowerCase(), [Validators.required]],
            'flag_url': [this.flagSrc + '/' + country.code.toLowerCase() + '.webp'],
            'search_key_words': [country.search_key_words]
          })
        }
        this.selectedCategories = result.country_details.visa_type ? result.country_details.visa_type.split(',') : '';
      })
    
  }

  onSubmit() {
    this.countryForm.get('visa_type').setValue(this.selectedCategories.length>0 ? this.selectedCategories.join(","): '');
    if (this.countryForm.valid) {
      if(!this.fromAdd){
        this.api.updateCountryDetails(this.countryData.id, this.countryForm.value).subscribe(
          (result: any) => {
            this.countryForm.value.id = this.countryData.id;
            this.toastr.success("Updated Successfully!");
            this.closeModal.emit();
          })
      } else {
        if(this.isCountryDetails){
          this.api.updateCountryDetails(this.countryDetailsId, this.countryForm.value).subscribe(
            (result: any) => {
              this.countryForm.value.id = this.addedCountryId = result.id;
              this.sharedService.selectedCountryId = result.id
              this.showNextStep.emit({ tab: 'country', data: this.countryForm.value });
            })
        } else {
          this.api.setCountryDetails(this.countryForm.value).subscribe(
            (result: any) => {
              this.countryForm.value.id = this.addedCountryId = result.id;
              this.sharedService.selectedCountryId = result.id;
              this.showNextStep.emit({ tab: 'country', data: this.countryForm.value });
            })
        }
      }
    } else {
      this.isSubmitted = true;
      this.toastr.warning("Fill all the fields to continue");
    }
  }

  checkURLParam(url){
    clearTimeout(this.checkInterval);
    let data:any = {country_url_param: url};
    if(this.isEdit) {data.id = this.countryId;}
    this.checkInterval = setTimeout(() => {
      this.sharedService.checkUrlParam(data).subscribe(
        result=>{
          this.isDupUrl = result.is_exists;
          if(this.isDupUrl) this.toastr.warning("URL Param must be unique!");
        }
      )
    }, 1000);
  }

}
