<div class="container">
    <form [formGroup]="infoForm" (ngSubmit)="onSubmit(infoForm)">
        <div class="row">
            <div class="col-md-4 mt-2" formArrayName="info" *ngFor="let item of info().controls; let i = index;">
                <div class="border shadow bg-white p-4 mt-2" [formGroupName]="i">
                    <a href="javascript:void(0)" class="ms-2 close-btn" (click)="removeinfo(i)"><i
                            class="uil-times fa-2x"></i></a>
                    <div class="row">
                        <div class="mt-2 col-md-12">
                            <label class="col-form-label">Text</label>
                            <input type="text" class="form-control" placeholder="Text" formControlName="text"
                                (change)="dataChanged(i)"
                                [ngClass]="{'invalid': item.get('text').invalid && isSubmitted}">
                        </div>
                        <div class="mt-2 col-md-12">
                            <div class="form-check form-switch form-switch-lg m-auto float-start" dir="ltr">
                                <label class="col-form-label cpr" for="customEnabled{{i}}">Enabled</label>
                                <input type="checkbox" formControlName="is_enabled" class="form-check-input mt-2"
                                    id="customEnabled{{i}}" (change)="dataChanged(i)">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 p-5">
                <div class="row">
                    <button class="btn btn-primary mt-1 me-1 col-md-12" type="button" (click)="addinfo()">
                        <i class="uil-plus"></i> Add
                    </button>

                    <button class="btn btn-info mt-1 col-md-12" type="submit">
                        <i class="bx bx-save"></i> Save All
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>