<div class="container-fluid set-minwh mt-4">
    <div class="container">
        <form [formGroup]="benefitsForm" (ngSubmit)="submitPayBenefits()">
            <div class="row">
                <div class="col-md-4" formArrayName="paybenefits"
                    *ngFor="let paybenefit of paybenefits.controls; let i = index">
                    <div [formGroupName]="i" class="border shadow p-3 mb-3">
                        <a class="float-end text-danger cpr" (click)="removePaybenefits(i)"><i
                                class="fa fa-times"></i></a>
                        <!-- Heading -->
                        <div>
                            <label class="col-form-label">Heading</label>
                            <input type="text" class="form-control" formControlName="heading" placeholder="Heading"
                                (change)="datachanged(i)"
                                [ngClass]="{ 'is-invalid': paybenefit.get('heading')?.invalid && paybenefit.get('heading')?.touched }" />
                            <div *ngIf="paybenefit.get('heading')?.invalid && paybenefit.get('heading')?.touched"
                                class="invalid-feedback">
                                Heading is required.
                            </div>
                        </div>

                        <!-- Benefits -->
                        <div class="mt-3">
                            <label class="col-form-label">Benefits</label>
                            <div formArrayName="benefits">
                                <div *ngFor="let benefit of getBenefits(i); let j = index"
                                    class="d-flex align-items-center mb-2">
                                    <input type="text" class="form-control" [formControlName]="j"
                                        placeholder="Enter benefit" (change)="datachanged(i)"
                                        [ngClass]="{ 'is-invalid': benefit.invalid && benefit.touched }" />
                                    <button type="button" class="btn btn-danger ms-2"
                                        (click)="datachanged(i);removeBenefit(i, j)">
                                        <i class="fa fa-times"></i>
                                    </button>
                                    <div *ngIf="benefit.invalid && benefit.touched" class="invalid-feedback">
                                        Benefit is required.
                                    </div>
                                </div>
                            </div>
                            <button type="button" class="btn btn-primary mt-2" (click)="addBenefit(i)"><i
                                    class="fa fa-plus"></i>
                                Benefit</button>
                        </div>

                        <!-- Link -->
                        <div class="mt-3">
                            <label class="col-form-label">Link</label>
                            <input type="url" class="form-control" formControlName="link" placeholder="Enter URL"
                                (change)="datachanged(i)"
                                [ngClass]="{ 'is-invalid': paybenefit.get('link')?.invalid && paybenefit.get('link')?.touched }" />
                            <div *ngIf="paybenefit.get('link')?.invalid && paybenefit.get('link')?.touched"
                                class="invalid-feedback">
                                Valid price is required.
                            </div>
                        </div>


                        <!-- Tags -->
                        <div class="mt-3">
                            <label class="col-form-label">Tags</label>
                            <div formArrayName="tags">
                                <div *ngFor="let tag of getTags(i); let k = index"
                                    class="d-flex align-items-center mb-2">
                                    <input type="text" class="form-control" [formControlName]="k"
                                        placeholder="Enter Tag" (change)="datachanged(i)"
                                        [ngClass]="{ 'is-invalid': tag.invalid && tag.touched }" />
                                    <button type="button" class="btn btn-danger ms-2"
                                        (click)="datachanged(i);removeTag(i, k)">
                                        <i class="fa fa-times"></i>
                                    </button>
                                    <div *ngIf="tag.invalid && tag.touched" class="invalid-feedback">
                                        Tag is required.
                                    </div>
                                </div>
                            </div>
                            <button type="button" class="btn btn-primary mt-2" (click)="addTag(i)"><i
                                    class="fa fa-plus"></i>
                                Tag</button>
                        </div>

                        <!-- Icon -->
                        <div class="mt-3">
                            <label class="col-form-label">Icon</label>
                            <div class=" d-flex">
                                <input type="text" class="form-control" placeholder="URL" formControlName="icon">
                                <a [href]="benefitsForm.value.paybenefits[i].icon" target="_blank">
                                    <img class="ms-1" height="40" [src]="benefitsForm.value.paybenefits[i].icon">
                                </a>
                                <button class="btn btn-primary ms-1" type="button"
                                    (click)="openUploadModal(i, 'icon')"><i
                                        class="fas fa-cloud-upload-alt"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Submit -->
            <div class="mt-3 text-end">
                <button type="button" class="btn btn-primary me-3" (click)="addPaybenefits()"><i class="fa fa-plus"></i>
                    Add-On</button>
                <button type="submit" class="btn btn-success">Submit</button>
            </div>
        </form>

    </div>
</div>