import { Component } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from "ngx-toastr";
import { ProductService } from "src/app/core/services/product.service";
import { ProductV2Service } from "src/app/core/services/product-v2.service";
import { SharedService } from "src/app/core/services/shared.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UploadGalleryComponent } from "src/app/shared/ui/upload-gallery/upload-gallery.component";


@Component({
  selector: 'app-add-on-addon',
  templateUrl: './add-on-addon.component.html',
  styleUrls: ['./add-on-addon.component.scss']
})
export class AddOnAddonComponent {

  addonsForm: FormGroup;
  countryList = this.sharedService.addedCountryList;
  country = this.sharedService.selectedCountryId;
  isUpdated: boolean = false;
  typeId: any;
  selected = false;
  deletedAddons: any = [];
  sources = [];

  constructor(
    private sharedService: SharedService,
    private api: ProductService,
    private apiv2: ProductV2Service,
    private fb: FormBuilder,
    private tosatr: ToastrService,
    private modalService: NgbModal
  ) {
    this.addonsForm = this.fb.group({
      addons: this.fb.array([])
    });
  }

  ngOnInit(): void {
    if (this.countryList.length == 0) this.getCountriesList();
    if (this.country) this.getAddOnForm();
    this.getSources();
  }

  initializeAddons(addons: any[]): void {
    const addonsArray = this.addonsForm.get('addons') as FormArray;
    if (addons.length > 0) {
      addons.forEach(addon => {
        addonsArray.push(this.createAddonGroup(addon));
      });
    } else {
      addonsArray.push(this.createEmptyAddonGroup());
    }
    this.deletedAddons = [];
  }

  createAddonGroup(addon: any): FormGroup {
    return this.fb.group({
      id: [addon.id],
      addon: [addon.addon, Validators.required],
      benefits: this.fb.array(addon.benefits.map((benefit: string) => this.fb.control(benefit, Validators.required))),
      price: [addon.price, [Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/)]],
      applicable_type: [addon.applicable_type, Validators.required],
      sources: [addon.sources, Validators.required],
      is_enabled: [addon.is_enabled || false],
      // sources: this.fb.array(addon.sources.map((source: string) => this.fb.control(source, Validators.required))),
      is_new: [false],
      is_updated: [false],
      icon: [addon.icon, Validators.required]
    });
  }

  createEmptyAddonGroup(): FormGroup {
    return this.fb.group({
      addon: ['', Validators.required],
      benefits: this.fb.array([this.fb.control('', Validators.required)]),
      price: ['', [Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/)]],
      applicable_type: ['', Validators.required],
      sources: ['', Validators.required],
      // sources: this.fb.array([this.fb.control('', Validators.required)]),
      is_new: [true],
      is_updated: [false],
      is_enabled: [true],
      icon: ['', Validators.required]
    });
  }

  get addons() {
    return this.addonsForm.get('addons') as FormArray;
  }

  addonsFb(): FormArray {
    return this.addonsForm.get('addons') as FormArray;
  }

  getBenefits(addonIndex: number) {
    return (this.addons.at(addonIndex).get('benefits') as FormArray).controls;
  }

  // getSources(addonIndex: number) {
  //   return (this.addons.at(addonIndex).get('sources') as FormArray).controls;
  // }

  addBenefit(addonIndex: number) {
    const benefitsArray = this.addons.at(addonIndex).get('benefits') as FormArray;
    benefitsArray.push(this.fb.control('', Validators.required));
  }

  removeBenefit(addonIndex: number, benefitIndex: number) {
    const benefitsArray = this.addons.at(addonIndex).get('benefits') as FormArray;
    benefitsArray.removeAt(benefitIndex);
  }

  // addSource(addonIndex: number) {
  //   const sourcesArray = this.addons.at(addonIndex).get('sources') as FormArray;
  //   sourcesArray.push(this.fb.control('', Validators.required));
  // }

  // removeSource(addonIndex: number, sourceIndex: number) {
  //   const sourcesArray = this.addons.at(addonIndex).get('sources') as FormArray;
  //   sourcesArray.removeAt(sourceIndex);
  // }

  selectCountry(country) {
    this.country = country;
    this.sharedService.selectedCmsCountryId = country;
    this.sharedService.selectedCmsCategoryId = null;
    this.sharedService.cmsCategoryList = [];

    this.sharedService.selectedCmsProductId = null;
    this.sharedService.cmsProductList = [];
  }

  getCountriesList() {
    this.api.getCountryDetails().subscribe((response: any) => {
      this.countryList = response;
      this.sharedService.addedCountryList = this.countryList;
      this.selected = true;
    });
  }

  getAddOnForm() {
    this.selectCountry(this.country);
    this.apiv2.getAddonDetails(this.country).subscribe((res) => {
      this.addonsForm = this.fb.group({
        addons: this.fb.array([])
      });
      this.initializeAddons(res['addon_data'] || []);
    });
  }

  addAddon() {
    const addonsArray = this.addonsForm.get('addons') as FormArray;
    addonsArray.push(this.createEmptyAddonGroup());
  }

  openUploadModal(i, field) {
    const modalRef = this.modalService.open(UploadGalleryComponent,
      {
        size: 'xl',
        scrollable: true,
        windowClass: 'modal-big-lg',
        keyboard: false,
        backdrop: 'static'
      });
    modalRef.componentInstance.uploadDetails = {};
    modalRef.result.then((result) => {
      if (result.status) {
        this.addonsForm.get(['addons', i, field]).setValue(result.image);
        this.addonsForm.value.addons[i].is_updated = true;
      }
    });
  }

  submitAddOn() {
    if (!this.addonsForm.valid) {
      this.addonsForm.markAllAsTouched();
      this.tosatr.warning("Fill All Fields!");
      return;
    }

    const addonsList = [
      ...this.addonsForm.value.addons.map(addon => ({
        ...addon,
        is_updated: !addon.is_new ? addon.is_updated : undefined,
        is_new: addon.is_new ? true : undefined,
      })),
      ...this.deletedAddons
    ];

    const formData = {
      country_id: this.country,
      addon_data: addonsList,
    };


    this.apiv2.setAddonDetails(formData).subscribe({
      next: () => {
        this.tosatr.success("Success", "Add-On Details Updated successfully");
        this.getAddOnForm();
      },
      error: (err) => {
        console.error(err);
      },
    });
  }


  removeAddon(i: number) {
    const addonsArray = this.addonsForm.get('addons') as FormArray;
    this.addonsForm.value.addons[i].is_deleted = true;
    if (this.addonsForm.value.addons[i].hasOwnProperty("id")) this.deletedAddons.push(this.addonsForm.value.addons[i]);

    // Ensure there's at least one form remaining
    if (addonsArray.length > 1) {
      addonsArray.removeAt(i);
    } else {
      this.tosatr.warning('At least one addon must be present.');
    }
  }

  datachanged(i) {
    this.addonsForm.get(['addons', i, 'is_updated']).setValue(true);
  }

  getSources() {
    this.apiv2.getWhitelabelSources().subscribe((response: any) => {
      this.sources = response;
    });
  }

}
