<div class="container-fluid set-minwh">
    <div class="my-3 row">
        <div class="offset-md-8 col-md-4">
            <ng-select [(items)]="countryList" [multiple]="false" [placeholder]="'Select Country'" bindLabel="name"
                bindValue="id" [(ngModel)]="country" [clearable]="false" (change)="selectCountry(country)"></ng-select>
        </div>
    </div>

    <div class="container d-flex align-center justify-content-center text-warning mt-3" *ngIf="!country">
        Select a Country
    </div>

    <div class="container" *ngIf="country">
        <form class="row" [formGroup]="courierForm" (ngSubmit)="submitCourier(courierForm)" novalidate>
            <div class="offset-md-2 col-md-8 mt-2">
                <div class="border shadow p-4 mt-2 bg-white">
                    <div class="my-3 row">
                        <!-- Heading -->
                        <div class="col-md-6">
                            <label class="col-form-label mt-1">Heading</label>
                            <input type="text" class="form-control"
                                [ngClass]="{'is-invalid': courierForm.get('heading')?.invalid && courierForm.get('heading')?.touched}"
                                placeholder="Heading" formControlName="heading" />
                            <div class="invalid-feedback">Heading is required.</div>
                        </div>

                        <!-- Additional Price -->
                        <div class="col-md-6">
                            <label class="col-form-label mt-1">Additional Price</label>
                            <input type="number" class="form-control"
                                [ngClass]="{'is-invalid': courierForm.get('additional_price')?.invalid && courierForm.get('additional_price')?.touched}"
                                placeholder="Price" formControlName="additional_price" />
                            <div class="invalid-feedback">Additional Price is required.</div>
                        </div>

                        <!-- Applicable For -->
                        <div class="col-md-6">
                            <label class="col-form-label mt-1">Applicable For</label>
                            <input type="number" class="form-control"
                                [ngClass]="{'is-invalid': courierForm.get('applicable_for')?.invalid && courierForm.get('applicable_for')?.touched}"
                                placeholder="Applicable For" formControlName="applicable_for" />
                            <div class="invalid-feedback">Applicable For is required.</div>
                        </div>

                        <!-- Price -->
                        <div class="col-md-6">
                            <label class="col-form-label mt-1">Price</label>
                            <input type="number" class="form-control"
                                [ngClass]="{'is-invalid': courierForm.get('price')?.invalid && courierForm.get('price')?.touched}"
                                placeholder="Price" formControlName="price" />
                            <div class="invalid-feedback">Price is required.</div>
                        </div>

                        <!-- Icon -->
                        <div class="col-md-12">
                            <label class="col-form-label mt-1">Icon URL</label>
                            <div class="d-flex">
                                <input type="text" class="form-control" placeholder="URL" formControlName="icon">
                                <div class="d-flex">
                                    <a [href]="courierForm.value.icon" target="_blank"><img class="ms-1" height="40"
                                            [src]="courierForm.value.icon"></a>
                                    <button class="btn btn-primary ms-1" type="button"
                                        (click)="openUploadModal('icon')"><i
                                            class="fas fa-cloud-upload-alt"></i></button>
                                </div>
                            </div>
                        </div>

                        <!-- Sources -->
                        <div class="col-md-12">
                            <label class="col-form-label mt-1">Sources</label>
                            <ng-select [(items)]="sources" [multiple]="true" [placeholder]="'Select Sources'"
                                bindLabel="source" bindValue="source" formControlName="sources" [clearable]="false"
                                [ngClass]="{'is-invalid': courierForm.get('sources')?.invalid && courierForm.get('sources')?.touched}"></ng-select>
                            <div class="invalid-feedback">At least one source is required.</div>
                        </div>

                        <!-- Benefits -->
                        <div class="col-md-12">
                            <label class="col-form-label mt-1">Benefits</label>
                            <div formArrayName="benefits" class="benefits">
                                <div *ngFor="let benefit of benefits.controls; let i = index" class="benefit-item">
                                    <input type="text" class="form-control"
                                        [ngClass]="{'is-invalid': benefit.invalid && benefit.touched}"
                                        [formControlName]="i" placeholder="Enter benefit" />
                                    <div class="invalid-feedback">Benefit is required.</div>
                                    <button type="button" class="btn btn-sm btn-danger h-50" (click)="removeBenefit(i)">
                                        <i class="fa fa-times"></i>
                                    </button>
                                </div>
                                <button type="button" class="btn btn-sm btn-primary" (click)="addBenefit()">
                                    <i class="fa fa-plus"></i> Add
                                </button>
                            </div>
                        </div>

                        <!-- Submit Button -->
                        <div class="col-md-12">
                            <button class="btn btn-success mt-2 float-end" type="submit">
                                <i class="bx bx-save"></i> Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>

    </div>
</div>