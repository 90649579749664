import { Component } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { addOnForm } from "src/app/core/models/add-on.model";
import { ProductService } from "src/app/core/services/product.service";
import { SharedService } from "src/app/core/services/shared.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UploadGalleryComponent } from "src/app/shared/ui/upload-gallery/upload-gallery.component";

@Component({
  selector: "app-add-on-insurance",
  templateUrl: "./add-on-insurance.component.html",
  styleUrls: ["./add-on-insurance.component.scss"],
})
export class AddOnInsuranceComponent {
  constructor(
    private sharedService: SharedService,
    private api: ProductService,
    private fb: FormBuilder,
    private tosatr: ToastrService,
    private modalService: NgbModal
  ) { }

  countryList = this.sharedService.addedCountryList;
  country = this.sharedService.selectedCountryId;
  addOnForm: FormGroup;
  isUpdated: boolean = false;
  typeId: any;
  selected = false;

  ngOnInit(): void {
    this.initForm();
    if (this.countryList.length == 0) this.getCountriesList();
  }

  getAddOnForm() {
    this.selectCountry(this.country);
    this.api.getAddOnData(this.country).subscribe((res) => {
      this.initForm(res[0]);
      this.isUpdated = res[0]?.id ? true : false;
      this.typeId = res[0]?.id;
    });
  }

  selectCountry(country) {
    this.country = country;
    this.sharedService.selectedCmsCountryId = country;
    this.sharedService.selectedCmsCategoryId = null;
    this.sharedService.cmsCategoryList = [];

    this.sharedService.selectedCmsProductId = null;
    this.sharedService.cmsProductList = [];
  }

  initForm(data?: any) {
    this.addOnForm = this.fb.nonNullable.group<addOnForm>({
      heading: new FormControl(data?.heading ?? null, Validators.required),
      price_heading: new FormControl(data?.price_heading ?? null, Validators.required),
      logo: new FormControl(data?.logo ?? null, Validators.required),
      info: this.fb.array(
        (data?.info ?? []).map((info: string) => new FormControl(info, Validators.required))
      ),
      benefits: this.fb.array(
        (data?.benefits ?? []).map((benefit: string) => new FormControl(benefit, Validators.required))
      ),
      snackbar: new FormControl(data?.snackbar ?? null, Validators.required),
      insurance: new FormControl(data?.key_values[0]?.Insurance ?? null, Validators.required),
      insurance_71_80: new FormControl(data?.key_values[0]?.Insurance_71_80 ?? null, Validators.required),

    });
  }

  get info(): FormArray {
    return this.addOnForm.get('info') as FormArray;
  }

  get benefits(): FormArray {
    return this.addOnForm.get('benefits') as FormArray;
  }

  addInfo() {
    this.info.push(new FormControl('', Validators.required));
  }

  removeInfo(index: number) {
    this.info.removeAt(index);
  }

  addBenefit() {
    this.benefits.push(new FormControl('', Validators.required));
  }

  removeBenefit(index: number) {
    this.benefits.removeAt(index);
  }



  submitAddOn() {
    if (!this.addOnForm.valid) {
      this.addOnForm.markAllAsTouched();
      this.tosatr.warning("Fill All Fields!");
      return;
    }

    const formData = {
      ...this.addOnForm.value,
      country: this.country,
      key_values: [{
        Insurance: this.addOnForm.get("insurance").value,
        Insurance_71_80: this.addOnForm.get("insurance_71_80").value
      }]
    };

    const apiCall = this.isUpdated
      ? this.api.editAddOnData(this.typeId, formData)
      : this.api.postAddOnData(formData);

    apiCall.subscribe({
      next: (res) => {
        const successMessage = this.isUpdated
          ? "Insurance Details Updated successfully"
          : "Insurance Details Added successfully";
        this.tosatr.success("Success", successMessage);
        this.getAddOnForm();
      },
      error: (err) => {
        this.tosatr.error("Error", "An error occurred while processing your request.");
        console.error(err);
      },
    });
  }

  getCountriesList() {
    this.api.getCountryDetails().subscribe((response: any) => {
      this.countryList = response;
      this.sharedService.addedCountryList = this.countryList;
      this.selected = true;
    });
  }

  openUploadModal(field) {
    const modalRef = this.modalService.open(UploadGalleryComponent,
      {
        size: 'xl',
        scrollable: true,
        windowClass: 'modal-big-lg',
        keyboard: false,
        backdrop: 'static'
      });
    modalRef.componentInstance.uploadDetails = {};
    modalRef.result.then((result) => {
      if (result.status) {
        this.addOnForm.get(field).setValue(result.image);
      }
    });
  }

}
