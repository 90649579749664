import { Injectable } from "@angular/core";
import { retry } from "rxjs/operators";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class V3ServicesService {

  constructor(private http: HttpClient) { }

  getLogHomeCarousal(type): Observable<any> {
    return this.http.get(`${environment.apiUrl}/carousel/?type=${type}`).pipe(retry(2));
  }

  getCountryCarousal(id): Observable<any> {
    return this.http.get(`${environment.apiUrl}/country-carousel/?country=${id}`).pipe(retry(2));
  }

  postLoginHomeCarousal(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/carousel/`, data);
  }

  postCountryCarousal(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/country-carousel/`, data);
  }

  getYtLinks(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/youtube-link/`).pipe(retry(2));
  }

  postYtLink(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/youtube-link/`, data);
  }

  updateYtLink(id, data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/youtube-link/${id}/`, data);
  }

  deleteYtLink(id): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/youtube-link/${id}/`);
  }

  getHomeTrending(country): Observable<any> {
    return this.http.get(`${environment.apiUrl}/country-image/?country=${country}`).pipe(retry(2));
  }

  setHomeTrending(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/country-image/`, data);
  }

  updateHomeTrending(id, data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/country-image/${id}/`, data);
  }

  getFooterServices(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/footer-services/`).pipe(retry(2));
  }

  setFooterService(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/footer-services/`, data);
  }

  getOtherCategories(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/other-category/`).pipe(retry(2));
  }

  getSemData(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/sem-pages/sempages/`).pipe(retry(2));
  }

  getCategorySemData(catid): Observable<any> {
    return this.http.get(`${environment.apiUrl}/country-url-mapping/?category=${catid}`).pipe(retry(2));
  }

  setSemData(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/other-category/`, data);
  }

  updateSemData(id, data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/other-category/${id}/`, data);
  }

  getHomeCategories(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/category-positions/`).pipe(retry(2));
  }

  changeCategoryPosition(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/category-positions/`, data);
  }

  updateCategoryStatus(id, data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/category-positions/${id}`, data);
  }

  getVisaExpert(country): Observable<any> {
    return this.http.get(`${environment.apiUrl}/visa-expert/?country=${country}`).pipe(retry(2));
  }

  setVisaExpert(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/visa-expert/`, data);
  }

  updateVisaExpert(id, data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/visa-expert/${id}/`, data);
  }

  getAboutVisa(category): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/category/about-visa/?category=${category}`).pipe(retry(2));
  }

  setAboutVisa(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/category/about-visa/`, data);
  }

  getCategoryHeader(id): Observable<any> {
    return this.http.get(`${environment.apiUrl}/category-header/?category=${id}`).pipe(retry(2));
  }

  postCategoryHeader(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/category-header/`, data);
  }

  updateCategoryHeader(id, data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/category-header/${id}/`, data);
  }

  getProductBenefits(product): Observable<any> {
    return this.http.get(`${environment.apiUrl}/product-benefit/?product=${product}`).pipe(retry(2));
  }

  setProductBenefits(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/product-benefit/`, data);
  }

  setCategorySemData(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/country-url-mapping/`, data);
  }

  deleteCategorySemData(id): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/other-category/${id}/`);
  }

  getHeaderServices(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms/header-menu/`).pipe(retry(2));
  }

  setHeaderService(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms/header-menu/`, data);
  }

  getCountrySnackbar(country): Observable<any> {
    return this.http.get(`${environment.apiUrl}/country-snackbar/?country=${country}`).pipe(retry(2));
  }

  setCountrySnackbar(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/country-snackbar/`, data);
  }

  updateCountrySnackbar(id, data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/country-snackbar/${id}/`, data);
  }

  getCategorySnackbar(category): Observable<any> {
    return this.http.get(`${environment.apiUrl}/category-snackbar/?category=${category}`).pipe(retry(2));
  }

  setCategorySnackbar(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/category-snackbar/`, data);
  }

  updateCategorySnackbar(id, data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/category-snackbar/${id}/`, data);
  }

  getProductInfo(product): Observable<any> {
    return this.http.get(`${environment.apiUrl}/product-info/?product_id=${product}`).pipe(retry(2));
  }

  setProductInfo(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/product-info/`, data);
  }

}
