<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title"></h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">

  <form [formGroup]="formValues" (ngSubmit)="onSubmit()">

    <div class="mt-2 row">
      <div class="col-md-6">
        <label class="col-form-label">Field Name</label>
        <input type="text" class="form-control" placeholder="Name" formControlName="field_name" required>
      </div>

      <div class="col-md-6">
        <label class="col-form-label">Field Type</label>
        <ng-select [multiple]="false" id="field_type" formControlName="field_type" required
          (change)="changeFieldType()">
          <ng-option *ngFor="let ftOption of fieldTypeOptions" [value]="ftOption.id" [disabled]="false">
            {{ftOption.field_type}}
          </ng-option>
        </ng-select>
      </div>
    </div>

    <div class="row"
      *ngIf="this.formValues.value.field_type == 3 || this.formValues.value.field_type == 4 || this.formValues.value.field_type == 12 || this.formValues.value.field_type == 5">
      <div class="col-12">
        <div class="option_box d-flex flex-wrap position-relative">
          <div class="choice_add_button cpr">
            <i class="fas fa-plus" (click)="newAnswerChoice()">New</i>
            <button type="button" class="btn ms-2 btn-primary" (click)="changePosition()">Change position</button>
          </div>
          <div formArrayName="answer_choices"
            *ngFor="let choice of formValues.get('answer_choices').controls; let i = index" class="form-group me-4">
            <div [formGroupName]="i">
              <div class="d-flex justify-content-between align-items-center">
                <label class="col-form-label">
                  Choice {{ i + 1 }}
                </label>
                <div (click)="deleteAnswerChoice(i)" class="cpr">
                  <i class="mdi mdi-delete"></i>
                </div>
              </div>

              <input type="text" class="form-control" placeholder="Name" formControlName="answer_choice">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-md-6">
        <label class="col-form-label">Question</label>
        <textarea role="textbox" rows="3" class="w-100 form-control" formControlName="display_name" required></textarea>
      </div>
      <div class="col-md-6">
        <label class="col-form-label">Info</label>
        <textarea role="textbox" rows="3" class="w-100 form-control" formControlName="info"></textarea>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-md-6">
        <label class="col-form-label">Tag</label>
        <textarea role="textbox" rows="3" class="w-100 form-control" formControlName="tag" required></textarea>
      </div>
      <div class="col-md-6">
        <label class="col-form-label">Save As</label>
        <ng-select [items]="storVariOptions" bindLabel="name" bindValue="internal_name" groupBy="key"
          formControlName="storage_variable">
          <ng-template ng-optgroup-tmp let-item="item">
            {{item.name || item.key.toUpperCase()}}
          </ng-template>
        </ng-select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="row mt-2">
          <div class="col-12">
            <label class="col-form-label">
              Is Required
            </label>

            <div>
              <input type="checkbox" value="false" formControlName="is_required">
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="row mt-2">
          <div class="col-12">
            <label class="col-form-label">
              Verification Required
            </label>

            <div>
              <input type="checkbox" value="false" formControlName="verification_required">
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="row mt-2">
          <div class="col-12">
            <label class="col-form-label">
              Is This Question is Tabular Parent ?
            </label>

            <div>
              <input type="checkbox" value="false" formControlName="is_tabular_parent">
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="mt-2 row">
      <div class="col-md-6">
        <label class="col-form-label">Related Question</label>
        <!-- <input type="number" class="form-control" placeholder="Name" formControlName="related_question" required> -->
        <ng-select [multiple]="false" id="field_type" formControlName="related_question"
          (change)="relateQuestionChange($event)">
          <div *ngFor="let formQue of allFormQuestions">
            <ng-option [value]="formQue.id" [disabled]="false"
              *ngIf="(formQue.field_type==5 || formQue.field_type==12)">
              {{formQue.display_name}}
            </ng-option>
          </div>

        </ng-select>
      </div>

      <div class="col-md-6">
        <label class="col-form-label">Related Answer Condition </label>
        <ng-select [multiple]="false" id="field_type" formControlName="related_answer_condition">
          <ng-option *ngFor="let ansOption of answerChoicesOptions" [value]="ansOption.id" [disabled]="false">
            {{ansOption.answer_choice}}
          </ng-option>
        </ng-select>
      </div>
    </div>


    <div class="row mt-4">
      <div class="col-12 text-end">
        <button type="button" class="btn btn-danger me-4" *ngIf="openFor == _EDIT"
          (click)="delConfModal(confirmationModal)">
          Delete
        </button>
        <button type="button" class="btn btn-primary" type="submit" [disabled]="!formValues.valid">
          {{(openFor == _EDIT)? 'Save this Edit':'Save'}}
        </button>
      </div>
    </div>

  </form>

</div>


<!-- #region : MODAL FOR DELETE CONFIRMATION -->
<ng-template #confirmationModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Delete Data ?</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    This action will permanently delete data from server. Do you want to continue ?
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close('Save click')">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="deleteQuestion();modal.close('Delete')">
      Delete
    </button>
  </div>
</ng-template>
<!-- #endregion : MODAL FOR DELETE CONFIRMATION -->