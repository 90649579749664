import { Injectable } from '@angular/core';
import { retry } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProductV2Service {

  constructor(private http: HttpClient) { }

  getWhitelabelSources(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/whitelabel-sources/`).pipe(retry(2));
  }

  getCourierDetails(country: number): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/courier-details/?country=${country}`).pipe(retry(2));
  }

  setCourierDetails(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/courier-details/`, data)
  }

  updateCourierDetails(id, data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/courier-details/${id}/`, data)
  }

  getAddonDetails(country: number): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/addon-details/?country_id=${country}`).pipe(retry(2));
  }

  setAddonDetails(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/addon-details/`, data)
  }

  getPayBenefitDetails(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/payment-benefits/`).pipe(retry(2));
  }

  setPayBenefitDetails(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms/payment-benefits/`, data)
  }

}
