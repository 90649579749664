<div class="container">
    <form class="row" [formGroup]="warningForm" (ngSubmit)="onSubmit(warningForm)">
        <div class="col-md-6 offset-md-3 p-4 mt-2">
            <div class="border shadow p-4">
                <div class="mt-2 row">
                    <div class="col-md-12">
                        <label class="col-form-label">Text</label>
                        <input type="text" class="form-control" placeholder="Enter Sub Heading" formControlName="text"
                            [ngClass]="{'invalid': warningForm.get('text').invalid && isSubmitted}">
                    </div>
                </div>
                <div class="mt-2 row">
                    <div class="col-md-12">
                        <div class="form-check form-switch form-switch-lg m-auto float-start" dir="ltr">
                            <label class="col-form-label cpr" for="customWarning">Enabled</label>
                            <input type="checkbox" formControlName="is_enabled" class="form-check-input mt-2"
                                id="customWarning">
                        </div>
                    </div>
                </div>
                <div class="mt-2 row">
                    <div class="col-md-12">
                        <label class="col-form-label">Icon URL</label>
                        <div class=" d-flex">
                            <input type="text" class="form-control" placeholder="Icon Url" formControlName="icon">
                            <a [href]="warningForm.value.icon" target="_blank">
                                <img class="ms-1" height="40" [src]="warningForm.value.icon">
                            </a>
                            <button class="btn btn-primary ms-1" type="button" (click)="openUploadModal('icon')"><i
                                    class="fas fa-cloud-upload-alt"></i></button>
                        </div>
                    </div>
                </div>
                <div class="mx-2 my-4 row">
                    <div class="col-md-12">
                        <button class="btn btn-info float-end" [disabled]="!hasChanges"><i
                                [ngClass]="{'fa fa-pulse fa-spin fa-spinner': isUpdating,'bx bx-save': !isUpdating}"></i>
                            Save Changes</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>