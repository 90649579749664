import { Component, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CountryManagementService } from 'src/app/core/services/country-management.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadGalleryComponent } from 'src/app/shared/ui/upload-gallery/upload-gallery.component';


@Component({
  selector: 'app-product-warning',
  templateUrl: './product-warning.component.html',
  styleUrls: ['./product-warning.component.scss']
})
export class ProductWarningComponent {
  @Input() country;
  @Input() page;
  @Input() product;


  warningForm: FormGroup;
  hasChanges = false;
  isUpdating = false;
  isNew = false;
  isSubmitted = false;

  constructor(private fb: FormBuilder,
    private api: CountryManagementService,
    private toastr: ToastrService, private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.resetForm();
    this.getData();
  }

  resetForm(data?) {
    this.warningForm = this.fb.group({
      id: [data ? data.id : null],
      icon: [data ? data.icon : '', [Validators.required]],
      text: [data ? data.text : '', [Validators.required]],
      is_enabled: [data?.is_enabled ?? false],
      sub_category: [this.product],
    })

    this.onChanges();

  }

  onChanges(): void {
    this.warningForm.valueChanges.subscribe(() => {
      Object.keys(this.warningForm.controls).map((key) => {
        if (this.warningForm.get(key).dirty) this.hasChanges = true;
      })
    });
  }

  onSubmit(form) {
    if (form.valid) {
      this.isUpdating = true;
      if (this.isNew) {
        this.api.postProductWarning(form.value).subscribe(
          (result: any) => {
            this.getData();
            this.toastr.success("Content Added Successfully!");
            this.hasChanges = false;
            this.isUpdating = false;
            this.isNew = false;
          },
          () => {
            this.isUpdating = false;
          }
        )
      } else {
        this.api.setProductWarning(form.value.id, form.value).subscribe(
          (result: any) => {
            this.getData();
            this.toastr.success("Content Updated Successfully!");
            this.hasChanges = false;
            this.isUpdating = false;
          },
          () => {
            this.isUpdating = false;
          }
        )
      }
    } else {
      this.isSubmitted = true;
      this.toastr.warning("Fill all fields and Save!");
    }

  }

  getData() {
    this.api.getProductWarning(this.product).subscribe(
      (result: any) => {
        this.resetForm(result[0]);
        if (result.length == 0) this.isNew = true;
        else this.isNew = false;
      })
  }

  openUploadModal(field) {
    const modalRef = this.modalService.open(UploadGalleryComponent,
      {
        size: 'xl',
        scrollable: true,
        windowClass: 'modal-big-lg',
        keyboard: false,
        backdrop: 'static'
      });
    modalRef.componentInstance.uploadDetails = {};
    modalRef.result.then((result) => {
      if (result.status) {
        this.warningForm.get(field).setValue(result.image);
        this.hasChanges = true;
      }
    });
  }
}
