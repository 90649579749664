<div class="container-fluid set-minwh">
    <div class="my-3 row">
        <div class="offset-md-8 col-md-4">
            <ng-select [(items)]="countryList" [multiple]="false" [placeholder]="'Select Country'" bindLabel="name"
                bindValue="id" [(ngModel)]="country" [clearable]="false" (change)="getAddOnForm()"></ng-select>
        </div>
    </div>

    <div class="container d-flex align-center justify-content-center text-warning mt-3" *ngIf="!country">
        Select a Country
    </div>

    <div class="container" *ngIf="country">
        <form [formGroup]="addonsForm" (ngSubmit)="submitAddOn()">
            <div class="row">
                <div class="col-md-4" formArrayName="addons" *ngFor="let addon of addons.controls; let i = index">
                    <div [formGroupName]="i" class="border shadow p-3 mb-3">
                        <a class="float-end text-danger cpr" (click)="removeAddon(i)"><i class="fa fa-times"></i></a>
                        <!-- Addon Name -->
                        <div>
                            <label class="col-form-label">Add-on</label>
                            <input type="text" class="form-control" formControlName="addon" placeholder="Add-on Name"
                                (change)="datachanged(i)"
                                [ngClass]="{ 'is-invalid': addon.get('addon')?.invalid && addon.get('addon')?.touched }" />
                            <div *ngIf="addon.get('addon')?.invalid && addon.get('addon')?.touched"
                                class="invalid-feedback">
                                Add-on name is required.
                            </div>
                        </div>

                        <!-- Benefits -->
                        <div class="mt-3">
                            <label class="col-form-label">Benefits</label>
                            <div formArrayName="benefits">
                                <div *ngFor="let benefit of getBenefits(i); let j = index"
                                    class="d-flex align-items-center mb-2">
                                    <input type="text" class="form-control" [formControlName]="j"
                                        placeholder="Enter benefit" (change)="datachanged(i)"
                                        [ngClass]="{ 'is-invalid': benefit.invalid && benefit.touched }" />
                                    <button type="button" class="btn btn-danger ms-2"
                                        (click)="datachanged(i);removeBenefit(i, j)">
                                        <i class="fa fa-times"></i>
                                    </button>
                                    <div *ngIf="benefit.invalid && benefit.touched" class="invalid-feedback">
                                        Benefit is required.
                                    </div>
                                </div>
                            </div>
                            <button type="button" class="btn btn-primary mt-2" (click)="addBenefit(i)"><i
                                    class="fa fa-plus"></i>
                                Benefit</button>
                        </div>

                        <!-- Price -->
                        <div class="mt-3">
                            <label class="col-form-label">Price</label>
                            <input type="text" class="form-control" formControlName="price" placeholder="Enter price"
                                (change)="datachanged(i)"
                                [ngClass]="{ 'is-invalid': addon.get('price')?.invalid && addon.get('price')?.touched }" />
                            <div *ngIf="addon.get('price')?.invalid && addon.get('price')?.touched"
                                class="invalid-feedback">
                                Valid price is required.
                            </div>
                        </div>

                        <!-- Applicable Type -->
                        <div class="mt-3">
                            <label class="col-form-label">Applicable Type</label>
                            <ng-select [items]="['PER BOOKING', 'PER PERSON']" [multiple]="false"
                                (change)="datachanged(i)" [placeholder]="'Select Applicable Type'"
                                formControlName="applicable_type" [clearable]="false" (change)="selectCountry(country)"
                                [ngClass]="{ 'is-invalid': addon.get('applicable_type')?.invalid && addon.get('applicable_type')?.touched }"></ng-select>

                            <div *ngIf="addon.get('applicable_type')?.invalid && addon.get('applicable_type')?.touched"
                                class="invalid-feedback">
                                Applicable type is required.
                            </div>
                        </div>

                        <!-- Sources -->
                        <!-- <div class="mt-3">
                            <label class="col-form-label">Sources</label>
                            <div formArrayName="sources">
                                <div *ngFor="let source of getSources(i); let k = index"
                                    class="d-flex align-items-center mb-2">
                                    <input type="text" class="form-control" [formControlName]="k"
                                        placeholder="Enter source" (change)="datachanged(i)"
                                        [ngClass]="{ 'is-invalid': source.invalid && source.touched }" />
                                    <button type="button" class="btn btn-danger ms-2"
                                        (click)="datachanged(i);removeSource(i, k)">
                                        <i class="fa fa-times"></i>
                                    </button>
                                    <div *ngIf="source.invalid && source.touched" class="invalid-feedback">
                                        Source is required.
                                    </div>
                                </div>
                            </div>
                            <button type="button" class="btn btn-primary mt-2" (click)="addSource(i)"><i
                                    class="fa fa-plus"></i>
                                Source</button>
                        </div> -->
                        <div class="col-md-12">
                            <label class="col-form-label mt-1">Sources</label>
                            <ng-select [(items)]="sources" [multiple]="true" [placeholder]="'Select Sources'"
                                bindLabel="source" bindValue="source" formControlName="sources" [clearable]="false"
                                (change)="datachanged(i)"
                                [ngClass]="{'is-invalid': addonsForm.get('sources')?.invalid && addonsForm.get('sources')?.touched}"></ng-select>
                            <div class="invalid-feedback">At least one source is required.</div>
                        </div>

                        <!-- Icon -->
                        <div class="mt-3">
                            <!-- <input type="text" class="form-control" formControlName="icon" placeholder="Enter icon"
                                (change)="datachanged(i)"
                                [ngClass]="{ 'is-invalid': addon.get('icon')?.invalid && addon.get('icon')?.touched }" />
                            <div *ngIf="addon.get('icon')?.invalid && addon.get('icon')?.touched"
                                class="invalid-feedback">
                                Icon is required.
                            </div> -->
                            <label class="col-form-label">Icon</label>
                            <div class=" d-flex">
                                <input type="text" class="form-control" placeholder="URL" formControlName="icon">
                                <a [href]="addonsForm.value.addons[i].icon" target="_blank">
                                    <img class="ms-1" height="40" [src]="addonsForm.value.addons[i].icon">
                                </a>
                                <button class="btn btn-primary ms-1" type="button"
                                    (click)="openUploadModal(i, 'icon')"><i
                                        class="fas fa-cloud-upload-alt"></i></button>
                            </div>
                        </div>
                        <div class="mt-3">
                            <div class="form-check form-switch form-switch-lg d-inline-flex align-items-center"
                                dir="rtl">
                                <label class="col-form-label" for="customSwitchsizelg">Enable</label>
                                <input type="checkbox" formControlName="is_enabled" class="form-check-input"
                                    id="customSwitchsizelg" (change)="datachanged(i)" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Submit -->
            <div class="mt-3 text-end">
                <button type="button" class="btn btn-primary me-3" (click)="addAddon()"><i class="fa fa-plus"></i>
                    Add-On</button>
                <button type="submit" class="btn btn-success">Submit</button>
            </div>
        </form>

    </div>
</div>