<div class="container-fluid">
    <!-- start page title -->
    <app-page-title title="General Page" [breadcrumbItems]="breadCrumbItems"></app-page-title>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <!-- orientation="vertical"  -->
                    <ul ngbNav #justifiednavpills="ngbNav" [activeId]="1" class="nav-pills nav-justified bg-light">
                        <li [ngbNavItem]="1">
                            <a ngbNavLink>
                                <span>Privacy&nbsp;Policy</span>
                            </a>
                            <ng-template ngbNavContent>
                                <app-privacy-policy></app-privacy-policy>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="2">
                            <a ngbNavLink>
                                <span>Terms&nbsp;and&nbsp;Conditions</span>
                            </a>
                            <ng-template ngbNavContent>
                                <app-terms-and-conditions></app-terms-and-conditions>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="3">
                            <a ngbNavLink>
                                <span>User&nbsp;Agreements</span>
                            </a>
                            <ng-template ngbNavContent>
                                <app-user-agreement></app-user-agreement>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="4">
                            <a ngbNavLink>
                                <span>Country&nbsp;Keywords</span>
                            </a>
                            <ng-template ngbNavContent>
                                <app-country-keyword></app-country-keyword>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="5">
                            <a ngbNavLink>
                                <span>Payment&nbsp;Benefits</span>
                            </a>
                            <ng-template ngbNavContent>
                                <app-payment-benefits></app-payment-benefits>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="6">
                            <a ngbNavLink>
                                <span>Footer&nbsp;Section</span>
                            </a>
                            <ng-template ngbNavContent>
                                <app-footer></app-footer>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="justifiednavpills"></div>
                </div>
            </div>
        </div>
    </div>
</div>