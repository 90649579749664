import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'Product Management',
        icon: 'bx bxs-cart-alt',
        subItems: [
            {
                id: 2,
                label: 'Country',
                link: '/products/country',
                parentId: 1
            },
            {
                id: 3,
                label: 'Jurisdiction',
                link: '/products/jurisdiction',
                parentId: 1
            },
            {
                id: 5,
                label: 'Category',
                link: '/products/category',
                parentId: 1
            },
            {
                id: 6,
                label: 'Age Group',
                link: '/products/age-group',
                parentId: 1
            },
            {
                id: 7,
                label: 'Products',
                link: '/products/products',
                parentId: 1
            },
            {
                id: 8,
                label: 'Add 0n',
                link: '/products/add-on',
                parentId: 1
            },
            // {
            //     id: 7,
            //     label: 'Pricing',
            //     link: '/products/pricing',
            //     parentId: 1
            // },
            // {
            //     id: 7,
            //     label: 'Required Docs',
            //     link: '/products/required-docs',
            //     parentId: 1
            // }
        ]
    },
    {
        id: 9,
        label: 'Content Management',
        icon: 'uil-subject',
        subItems: [
            {
                id: 10,
                label: 'Home Page',
                link: '/content/home-page',
                parentId: 9
            },
            {
                id: 11,
                label: 'Country Page',
                link: '/content/country-page',
                parentId: 9
            },
            {
                id: 12,
                label: 'Category Page',
                link: '/content/category-page',
                parentId: 9
            },
            {
                id: 13,
                label: 'Product Page',
                link: '/content/product-page',
                parentId: 9
            },
            {
                id: 14,
                label: 'Consultant Page',
                link: '/content/consultant-page',
                parentId: 9
            },
            {
                id: 15,
                label: 'About Us Page',
                link: '/content/about-us-page',
                parentId: 9
            },
            {
                id: 16,
                label: 'Contact Us Page',
                link: '/content/contact-us-page',
                parentId: 9
            },
            {
                id: 17,
                label: 'Testimonial Page',
                link: '/content/testimonial-page',
                parentId: 9
            },
            {
                id: 18,
                label: 'FAQ Page',
                link: '/content/faq',
                parentId: 9
            },
            {
                id: 46,
                label: 'SEM Page',
                link: '/content/sem',
                parentId: 9
            },
            {
                id: 19,
                label: 'SEO Section',
                link: '/content/seo',
                parentId: 9
            },
            {
                id: 20,
                label: 'General',
                link: '/content/general',
                parentId: 9
            },
            {
                id: 21,
                label: 'News',
                icon: 'uil-newspaper',
                parentId: 9,
                subItems: [
                    {
                        id: 22,
                        label: 'News',
                        parentId: 9,
                        link: '/content/news',
                    },
                    {
                        id: 23,
                        label: 'Specific',
                        parentId: 9,
                        link: '/content/news-specific',
                    },
                    {
                        id: 24,
                        label: 'Author',
                        parentId: 9,
                        link: '/content/news-author',
                    },
                ]
            },
            {
                id: 25,
                label: 'Blog',
                icon: 'uil-newspaper',
                parentId: 9,
                subItems: [
                    {
                        id: 26,
                        label: 'Blog',
                        parentId: 9,
                        link: '/content/blog',
                    },
                    {
                        id: 27,
                        label: 'Specific',
                        parentId: 9,
                        link: '/content/specific',
                    },
                    {
                        id: 28,
                        label: 'Author',
                        parentId: 9,
                        link: '/content/author',
                    },
                ]
            }
        ]
    },
    {
        id: 29,
        label: 'Visa Management',
        icon: 'uil-newspaper',
        subItems: [
            {
                id: 30,
                label: 'Visa Pro',
                link: '/visa/process',
                parentId: 29
            },
            {
                id: 31,
                label: 'DIY',
                link: '/visa/diy',
                parentId: 29
            },
            // {
            //     id: 29,
            //     label: 'Questions',
            //     link: '/visa/questions',
            //     parentId: 27
            // },
            // {
            //     id: 30,
            //     label: 'Visa Flow',
            //     link: '/visa/visa-flow',
            //     parentId: 27
            // },
        ]
    },
    {
        id: 32,
        label: 'Settings',
        icon: 'uil-cog',
        subItems: [
            {
                id: 33,
                label: 'Reports',
                link: '',
                parentId: 32,
                subItems: [
                    {
                        id: 34,
                        label: 'Charts',
                        parentId: 32,
                        link: '/settings/pie-charts',
                    },
                    {
                        id: 35,
                        label: 'Date Table Report',
                        parentId: 32,
                        link: '/settings/date-table',
                    },
                    {
                        id: 45,
                        label: 'Country Table Report',
                        parentId: 32,
                        link: '/settings/country-table',
                    },
                ]
            },
            {
                id: 36,
                label: 'Arise Coupon',
                link: '/settings/arise-coupon',
                parentId: 18
            },
            {
                id: 37,
                label: 'Access Management',
                link: '/settings/access-management',
                parentId: 18
            },
            {
                id: 38,
                label: 'Global Questions',
                link: '/settings/global-questions',
                parentId: 18
            },
            {
                id: 39,
                label: 'Work Flow',
                link: '/settings/work-flow',
                parentId: 18
            },
            {
                id: 23,
                label: 'Country Questions',
                link: '/settings/country-questions',
                parentId: 18
            },
            {
                id: 40,
                label: 'Templates',
                link: '/settings/templates',
                parentId: 18
            },
            {
                id: 41,
                label: 'Integrations',
                link: '/settings/integrations',
                parentId: 18
            },
            // {
            //     id: 26,
            //     label: 'Delete Data',
            //     link: '/settings/delete-data',
            //     parentId: 18
            // }

        ]
    },
    {
        id: 42,
        label: 'Lead Management',
        icon: 'uil-subject',
        subItems: [
            {
                id: 43,
                label: 'Collections',
                link: '/leads/collections',
                parentId: 42
            },
            {
                id: 44,
                label: 'Logs',
                link: '/leads/logs',
                parentId: 42
            },
            {
                id: 45,
                label: 'Feedbacks',
                link: '/leads/feedbacks',
                parentId: 42
            }
        ]
    }
];

